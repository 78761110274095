import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import "./payment.scss";
import { AppContext } from "../../componentsWrapper";
import { isNotNull } from "../../../Utils/Utils";

function AddPaymentMethodForm() {
  const context = useContext(AppContext);
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [existingCardInfo, setExistingCardInfo] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const { id } = useParams();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const checkPaymentDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/payment/${id}/details`,
          {
            headers: {
              Authorization: `Bearer ${await getAccessTokenSilently()}`,
            },
          }
        );

        if (isNotNull(response.data) && response.status === 200) {
          setExistingCardInfo(response.data);
        }
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    checkPaymentDetails();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setLoading(true);
    setError(null);

    try {
      // Create a PaymentMethod from card details
      const { paymentMethod, error: methodError } =
        await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
        });

      if (methodError) {
        setError(methodError.message);
        setLoading(false);
        return;
      }

      // Send the PaymentMethod ID to the backend
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/payment/${id}/attach`,
        { paymentMethodId: paymentMethod.id },
        {
          headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
          },
        }
      );

      if (response.status === 200) {
        setSuccess(true);
        toast.success("Payment method added successfully!");
      } else {
        toast.error("Failed to add payment method.");
        throw new Error("Failed to attach payment method.");
      }
    } catch (err) {
      console.error(err);
      setError(err.response?.data?.message || "An unexpected error occurred.");
      toast.error("Failed to add payment method.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="addPaymentWrapper">
      <div className="paymentHeader">Payment method</div>
      <div className="paymentSubHeader">
        Add a payment method to pay for your subscription
      </div>

      {existingCardInfo && (
        <div className="existingCardInfo">
          <div className="existingCardInfo">
            <p>
              Card: {existingCardInfo.brand} **** **** ****
              {existingCardInfo.last4Digits}
            </p>
            <p>Expires: {existingCardInfo.expiration}</p>
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit} className="addPaymentForm">
        <CardElement />
        <Button
          type="submit"
          disabled={!stripe || loading}
          className="paymentBtn"
        >
          {loading ? "Saving..." : "Save"}
        </Button>
        {error && <div style={{ color: "red" }}>{error}</div>}
      </form>
    </div>
  );
}

export default AddPaymentMethodForm;
