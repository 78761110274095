import React, { useContext, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import SignUp from "./signUp/signUp";
import ManageUsercompany from "./company/ManageUserCompany.jsx";
import CreateUserCompany from "./company/CreateUserCompany.jsx";
import { ToastContainer } from "react-toastify";
import Employees from "./company/employees/Employees.jsx";
import AddPosition from "./company/positions/AddPosition.jsx";
import PositionsList from "./company/positions/PositionsList.jsx";
import StripeWrapper from "./company/payments/StripeWrapper.js";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";

export const AppContext = React.createContext();

const ComponentsWrapper = () => {
  const [currUser, setUser] = useState();
  const [companies, setCompanies] = useState();
  const { getAccessTokenSilently } = useAuth0();
  const { user } = useAuth0();

  useEffect(() => {
    if (user) {
      setUser(user);
    }
    const getcompanies = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/companies`,
          {
            headers: {
              Authorization: "Bearer " + (await getAccessTokenSilently()),
            },
          }
        );

        if (response.data.length === 0 || response.data === null) {
          setCompanies(null);
        } else {
          setCompanies(response.data);
        }
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };
    getcompanies();
  }, []);

  return (
    <AppContext.Provider value={{ companies, user }}>
      <Router>
        <Routes>
          <Route path="/" element={<SignUp />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/create-company" element={<CreateUserCompany />} />
          <Route path="/manage-company/:id" element={<ManageUsercompany />}>
            <Route path="employees" element={<Employees />} />
            <Route path="addPosition" element={<AddPosition />} />
            <Route path="positionsList" element={<PositionsList />} />
            <Route path="admin" element={<StripeWrapper />} />
          </Route>
        </Routes>
        <ToastContainer />
      </Router>
    </AppContext.Provider>
  );
};

export default ComponentsWrapper;
