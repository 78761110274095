import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { CustomPacmanLoader } from "../../commonComponents/PacmanLoader";
import { TextField, IconButton, Menu, MenuItem, Button } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import dayjs from "dayjs";
import "../employees/companies.scss";
import "./positionsList.scss";
import PositionForm from "./AddPositionForm.jsx";
import AttentionDialog from "../../commonComponents/AttentionDialog.jsx";
import { POSITION_STATUS } from "../../../Utils/constants";
import { toast } from "react-toastify";
import AddCandidates from "../candidates/AddCandidates";

const PositionsList = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const [positions, setPositions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectedPositionEdit, setSelectedPositionEdit] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedPositionCandidates, setSelectedPositionCandidates] =
    useState(null);

  useEffect(() => {
    if (!selectedPositionEdit) {
      setIsLoading(true);
      getPositionList();
    }
  }, [id, getAccessTokenSilently, selectedPositionEdit]);

  const getPositionList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/Positions/${id}`,
        {
          headers: {
            Authorization: "Bearer " + (await getAccessTokenSilently()),
          },
        }
      );
      setPositions(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const filteredPositions = positions.filter((position) =>
    searchTerm === ""
      ? true
      : position.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleMenuClick = (event, position) => {
    setAnchorEl(event.currentTarget);
    setSelectedPosition(position);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    handleMenuClose();
    setSelectedPositionEdit(selectedPosition);
    setSelectedPosition(null);
  };

  const handleChangePositionStatus = async (status) => {
    // Implement delete logic here, e.g., make an API call to delete the position
    try {
      //{companyId}/{positionId}/{status}
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/Positions/${id}/${selectedPosition.id}/status`,
        { status: status },
        {
          headers: {
            Authorization: "Bearer " + (await getAccessTokenSilently()),
          },
        }
      );

      if (response.status === 200 && status === POSITION_STATUS.Deleted) {
        setPositions((prevPositions) =>
          prevPositions.filter((pos) => pos.id !== selectedPosition.id)
        );
        toast.success("Position deleted successfully");
        setOpenDeleteDialog(false);
      } else if (response.status === 200) {
        setPositions((prevPositions) =>
          prevPositions.map((pos) =>
            pos.id === selectedPosition.id
              ? { ...pos, isClosed: response.data.isClosed }
              : pos
          )
        );
        const popUpMsg =
          status === POSITION_STATUS.Active
            ? "Position re-opened successfully"
            : "Position closed successfully";
        toast.success(popUpMsg);
      } else {
        toast.error("Error updating position status");
      }
      setSelectedPosition(null);
    } catch (error) {
      console.log("Error deleting position:", error);
      setSelectedPosition(null);
      toast.error("Error deleting position");
    }
  };

  const handleClose = () => {
    handleMenuClose();
  };

  return (
    <div className="positionsListPage">
      {isLoading && <CustomPacmanLoader />}
      {!isLoading && !selectedPositionEdit && !selectedPositionCandidates && (
        <>
          <div className="searchWrapper">
            <TextField
              className="searchPositionField"
              fullWidth
              variant="outlined"
              label="Search by Position Title"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size="small"
              style={{ marginBottom: "20px" }}
            />
          </div>

          <div className="positionsWrapper">
            {filteredPositions.length > 0 ? (
              filteredPositions.map((position) => (
                <div
                  key={position.id}
                  className="positionCard"
                  onClick={(e) => {
                    setSelectedPositionCandidates(position);
                    // e.stopPropagation();
                  }}
                >
                  <div className="positionTitle">
                    <span>{position.title}</span>
                    <IconButton
                      aria-label="more"
                      aria-controls="position-menu"
                      aria-haspopup="true"
                      onClick={(event) => {
                        handleMenuClick(event, position);
                        event.stopPropagation();
                      }}
                      className="positionMenuButton"
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </div>
                  <p className="positionLevel">
                    <span className="textTitle">Level:</span>
                    <span>{position.level}</span>
                  </p>
                  <p className="positionStatus">
                    <span className="textTitle">Status:</span>
                    <span>
                      {position.isClosed === false ? "Active" : "Closed"}
                    </span>
                  </p>
                  <p className="positionDate">
                    <span className="textTitle">Created:</span>
                    <span>
                      {dayjs(position.createdAt).format("DD/MM/YYYY")}
                    </span>
                  </p>
                  <p className="positionCandidates">
                    <span className="textTitle">Candidates:</span>
                    <span>{position.counters.candidates}</span>
                  </p>
                  <p className="positionCandidatesUndecided">
                    <span className="textTitle">Undecided:</span>
                    <span>{position.counters.undecidedCandidates}</span>
                  </p>
                </div>
              ))
            ) : (
              <p>No positions found.</p>
            )}
          </div>

          <Menu
            id="position-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleEdit}>Edit</MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuClose();
                setOpenDeleteDialog(true);
              }}
            >
              Delete
            </MenuItem>
            {!selectedPosition?.isClosed && (
              <MenuItem
                onClick={() => {
                  handleChangePositionStatus(POSITION_STATUS.Inactive);
                  handleClose();
                }}
              >
                Close Position
              </MenuItem>
            )}
            {selectedPosition?.isClosed && (
              <MenuItem
                onClick={() => {
                  handleChangePositionStatus(POSITION_STATUS.Active);
                  handleClose();
                }}
              >
                Re-open Position
              </MenuItem>
            )}
          </Menu>
        </>
      )}
      {selectedPositionEdit && (
        <div className="editPositionFormWrapper">
          <div
            onClick={() => setSelectedPositionEdit(null)}
            className="closeEdit"
          >
            Close edit mode without saving
          </div>
          <PositionForm
            positionData={selectedPositionEdit}
            isEditMode={true}
            setIsEditMode={setSelectedPositionEdit}
          />
        </div>
      )}
      <AttentionDialog
        open={openDeleteDialog}
        handleOK={() => handleChangePositionStatus(POSITION_STATUS.Deleted)}
        handleCancel={() => {
          setOpenDeleteDialog(false);
        }}
        okBtnText={"Delete"}
        cancelBtnText={"Cancel"}
        title={"Delete Position"}
        message={
          "Are you sure you want to delete this position? This action cannot be undone."
        }
      />
      {selectedPositionCandidates !== null && (
        <AddCandidates
          selectedPositionCandidates={selectedPositionCandidates}
          setSelectedPositionCandidates={setSelectedPositionCandidates}
        />
      )}
    </div>
  );
};

export default PositionsList;
