import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./Payments";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { CustomPacmanLoader } from "../../commonComponents/PacmanLoader";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function StripeWrapper() {
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getKey = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/payment/${id}/setup`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data) {
          setClientSecret(response.data);
        } else {
          throw new Error("Invalid client secret response");
        }
      } catch (err) {
        console.error("Error fetching client secret:", err);
        setError("Failed to initialize payment. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    getKey();
  }, [id, getAccessTokenSilently]);

  if (loading) {
    return (
      <div>
        <CustomPacmanLoader />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  const options = { clientSecret };

  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentForm />
    </Elements>
  );
}

export default StripeWrapper;
