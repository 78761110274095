import React, { useState, useEffect, useRef } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./addCandidates.scss";
import axios from "axios";
import {
  Tooltip,
  Box,
  Button,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import CandidateTable from "./CandidatesTable";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import {
  CANDIDATE_STATUS,
  DECISION_ON_CANDIDATE,
} from "../../../Utils/constants";
import CandidateDialog from "./CandidateDialog";
import RefreshIcon from "@mui/icons-material/Refresh";
import { toast } from "react-toastify";
import ClearIcon from "@mui/icons-material/Clear";
import { BeatLoader } from "react-spinners";

const AddCandidates = ({
  selectedPositionCandidates,
  setSelectedPositionCandidates,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const [candidatesList, setCandidatesList] = useState([]);
  const { id } = useParams();
  const [dialogOpen, setDialogOpen] = useState(false); // State to control dialog
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [positionInfo, setPositionInfo] = useState(null);
  const [cvsLoading, setCvsLoading] = useState(false);
  const intervalRef = useRef(null);

  //GET POSITION INFO
  useEffect(() => {
    const getPosition = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/positions/${id}/${selectedPositionCandidates.id}`,
          {
            headers: {
              Authorization: "Bearer " + (await getAccessTokenSilently()),
            },
          }
        );
        if (response.status === 200) {
          setPositionInfo(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getPosition();
  }, []);

  //UPLOAD FILES
  useEffect(() => {
    if (selectedFiles.length > 0) {
      const uploadFiles = async () => {
        try {
          const formData = new FormData();

          selectedFiles.map(async (file) => {
            formData.append("file", file);
          });

          const response = await axios.put(
            `${process.env.REACT_APP_BACKEND_URL}/api/storage/simulated-storage/${id}/${selectedPositionCandidates?.id}/uploadcvs?`,
            formData,
            {
              headers: {
                Authorization: "Bearer " + (await getAccessTokenSilently()),
                "Content-Type": "multipart/form-data", // Important for file uploads
              },
            }
          );
          await getPosition();
          console.log("Uploaded file URLs:", response);
        } catch (error) {
          console.error("Error uploading files:", error);
        }
      };

      uploadFiles();
    }
  }, [selectedFiles]);

  //GET CANDIDATES LIST
  useEffect(() => {
    // Function to check if there are any 'NotStarted' CVs
    const hasNotStartedCVs = () => {
      console.log(positionInfo?.cVsToProcess);
      return positionInfo?.cVsToProcess?.some(
        (cv) => cv.status === "NotStarted"
      );
    };

    if (hasNotStartedCVs()) {
      // If there are 'NotStarted' CVs, set up the interval
      if (!intervalRef.current) {
        intervalRef.current = setInterval(() => {
          console.log("Refreshing position info...");
          getPosition();
        }, 30000); // 30 seconds
      }
    } else {
      // If there are no 'NotStarted' CVs, clear the interval
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    }

    getCandidates();
    // Clean up the interval when the component unmounts or positionInfo changes
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [positionInfo]);

  const getPosition = async () => {
    setCvsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/positions/${id}/${selectedPositionCandidates.id}`,
        {
          headers: {
            Authorization: "Bearer " + (await getAccessTokenSilently()),
          },
        }
      );
      if (response.status === 200) {
        setPositionInfo(response.data);
        setCvsLoading(false);
      }
    } catch (error) {
      console.error(error);
      toast.error("Error getting position info");
      setCvsLoading(false);
    }
  };

  const getCandidates = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/candidate/${id}/${selectedPositionCandidates.id}/getcandidates`,
        {
          headers: {
            Authorization: "Bearer " + (await getAccessTokenSilently()),
          },
        }
      );
      if (response.status === 200) {
        const candidates = response.data.map((candidate) => ({
          ...candidate,
          candidateStatus: CANDIDATE_STATUS[candidate.candidateStatus],
          decision: DECISION_ON_CANDIDATE[candidate.decision],
        }));
        setCandidatesList(candidates);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      toast.error("Error getting candidates");
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles([...files]); // Convert FileList to an array
  };
  const handleButtonClick = () => {
    document.getElementById("fileInput").click();
  };

  //GET SELECTED CANDIDATE DETAILS
  const handleDetailsClick = async (candidate, isInvite) => {
    if (isInvite) {
      try {
        const response = await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/candidate/${id}/${candidate.id}/approveextractedcandidate`,
          {},
          {
            headers: {
              Authorization: "Bearer " + (await getAccessTokenSilently()),
            },
          }
        );
        if (response.status === 200) {
          toast.success("Interview invitation sent successfully");
          await getPosition();
        }
      } catch (error) {
        console.error(error);
        toast.error("Error sending interview invitation");
      }
      return;
    }

    setDialogOpen(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/candidate/${id}/${candidate.id}/getcandidate`,
        {
          headers: {
            Authorization: "Bearer " + (await getAccessTokenSilently()),
          },
        }
      );

      if (response.status === 200) {
        response.data = {
          ...response.data,
          candidateStatus: CANDIDATE_STATUS[response.data.candidateStatus],
          decision: DECISION_ON_CANDIDATE[response.data.decision],
        };
        setSelectedCandidate(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Handle closing the dialog
  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedCandidate(null);
  };

  // Add the click handler in the columns
  const columnsData = [
    {
      accessorKey: "fullName",
      header: "Name",
      enableEditing: (row) => row.original.isTemporary,
      enableClickToCopy: true,
    },
    {
      accessorKey: "userEmail",
      header: "Email",
      enableEditing: (row) => row.original.isTemporary,
      enableClickToCopy: true,
    },
    {
      accessorKey: "candidateStatus",
      header: "Status",
      enableEditing: false,
      Edit: () => null,
    },
    {
      accessorKey: "score",
      header: "Score",
      enableEditing: false,
      Edit: () => null,
    },
    {
      accessorKey: "decision",
      header: "Decision",
      enableEditing: false,
      Edit: () => null,
    },
    {
      accessorKey: "details",
      header: "Details",
      enableEditing: false,
      Edit: () => null,
      Cell: ({ row }) => (
        <div
          onClick={() =>
            handleDetailsClick(row.original, row.original.isTemporary)
          }
          style={{
            border: "1px solid #278aac",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            borderRadius: "5px",
            minWidth: "90px",
            width: "90px",
            textAlign: "center",
            height: "24px",
          }}
        >
          {row.original.isTemporary ? (
            <Tooltip
              title={"click to send interview invitation to selected candidate"}
            >
              <>Invite</>
            </Tooltip>
          ) : (
            <Tooltip title={"click to open details"}>
              <>Details</>
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  const removeErrorCV = async (cv) => {
    try {
      const cvPath = encodeURIComponent(cv.filePath);
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/positions/${id}/${selectedPositionCandidates.id}/${cvPath}/updateCvInProcessVisibility`,
        {},
        {
          headers: {
            Authorization: "Bearer " + (await getAccessTokenSilently()),
          },
        }
      );
      if (response.status === 200) {
        await getPosition();
      }
    } catch (error) {
      console.error(error);
      toast.error("Error removing CV"); // Add error message  to toast
    }
  };

  return (
    <div className="addCandidatesWrapper">
      <div className="addCandidatesHeader">
        <div
          className="backToPositions"
          onClick={() => setSelectedPositionCandidates(null)}
        >
          <ArrowBackIosIcon /> <span>Back to positions</span>
        </div>
        <div className="headerText">{selectedPositionCandidates.title}</div>
      </div>
      <div className="btnAddCandidates">
        <Button onClick={handleButtonClick}>
          Add Candidates
          <input
            type="file"
            id="fileInput"
            multiple
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </Button>
      </div>
      <div className="cvInProcess">
        <Accordion className="accordionCvInProcess">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            {cvsLoading ? (
              <BeatLoader className="beatLoaderCvs" color="#278aac" />
            ) : (
              <Typography className="cvInProcessHeader">
                Pending CVs: {positionInfo?.cVsToProcess.length}
                <Tooltip title="Refresh">
                  <RefreshIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      getPosition();
                    }}
                  />
                </Tooltip>
              </Typography>
            )}
          </AccordionSummary>
          {!cvsLoading && (
            <AccordionDetails>
              <Box className="inProcessList">
                <div className="textCvInProcess">In process:</div>

                {positionInfo?.cVsToProcess
                  ?.filter((cv) => cv.status === "NotStarted")
                  ?.map((cv) => (
                    <div key={cv.submittedFileName}>
                      <Typography>{cv.submittedFileName}</Typography>
                    </div>
                  ))}
              </Box>
              <Box className="errorList">
                <div className="textCvInProcess">Error processing CVs: </div>
                {positionInfo?.cVsToProcess
                  ?.filter((cv) => cv.status !== "NotStarted")
                  .map((cv) => (
                    <div key={cv.submittedFileName} className="cvList">
                      <Typography>
                        {cv.submittedFileName}
                        <Tooltip title="Remove">
                          <ClearIcon onClick={() => removeErrorCV(cv)} />
                        </Tooltip>
                      </Typography>
                    </div>
                  ))}
              </Box>
            </AccordionDetails>
          )}
        </Accordion>
      </div>
      <div className="candidatesTableWrapper">
        <CandidateTable
          candidates={candidatesList}
          columnsData={columnsData}
          loading={loading}
        />
      </div>
      {selectedCandidate && (
        <CandidateDialog
          dialogOpen={dialogOpen}
          handleCloseDialog={handleCloseDialog}
          selectedCandidate={selectedCandidate}
        />
      )}
    </div>
  );
};

export default AddCandidates;
