import React, { useState, useRef, useEffect, useContext } from "react";
import TopHeaderBar from "../commonComponents/topHeaderBar.jsx";
import { styled, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { MEDIA_QUERIES } from "../../Utils/constants.js";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { useNavigate, Outlet, useParams, useLocation } from "react-router-dom";
import ListAltIcon from "@mui/icons-material/ListAlt";
import CreditScoreIcon from "@mui/icons-material/CreditScore";

const ManageUsercompany = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const isTablet = useMediaQuery(MEDIA_QUERIES.TABLET_SMALL);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams(); // Getting the id for the company

  const menuRef = useRef(null);

  // Close the menu if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="manageCompanyWrapper">
      <div className="companyHeaderWrapper">
        <TopHeaderBar />
      </div>
      <div className="manageCompanyContent">
        <div
          ref={menuRef}
          className={
            isTablet
              ? open
                ? "sideMenuTabletOpen"
                : "sideMenuTabletClose"
              : "sideMenu"
          }
        >
          <div className={"sideMenuItems"}>
            <div
              className={`menuItem ${
                location.pathname.endsWith("/employees") ? "active" : ""
              }`}
              onClick={() => navigate(`employees`)}
            >
              {!isTablet && (
                <>
                  <BadgeOutlinedIcon /> <span>Employees</span>
                </>
              )}
              {isTablet &&
                (open ? (
                  <>
                    <BadgeOutlinedIcon /> <span>Employees</span>
                  </>
                ) : (
                  <BadgeOutlinedIcon />
                ))}
            </div>
            <div
              className={`menuItem ${
                location.pathname.endsWith("/addPosition") ? "active" : ""
              }`}
              onClick={() => navigate(`addPosition`)}
            >
              {!isTablet && (
                <>
                  <PostAddIcon /> <span>Add Position</span>
                </>
              )}
              {isTablet &&
                (open ? (
                  <>
                    <PostAddIcon /> <span>Add Position</span>
                  </>
                ) : (
                  <PostAddIcon />
                ))}
            </div>
            <div
              className={`menuItem ${
                location.pathname.endsWith("/positionsList") ? "active" : ""
              }`}
              onClick={() => navigate(`positionsList`)}
            >
              {!isTablet && (
                <>
                  <ListAltIcon /> <span>Positions</span>
                </>
              )}
              {isTablet &&
                (open ? (
                  <>
                    <ListAltIcon /> <span>Positions</span>
                  </>
                ) : (
                  <ListAltIcon />
                ))}
            </div>
            <div
              className={`menuItem ${
                location.pathname.endsWith("/admin") ? "active" : ""
              }`}
              onClick={() => navigate(`admin`)}
            >
              {!isTablet && (
                <>
                  <CreditScoreIcon /> <span>Admin</span>
                </>
              )}
              {isTablet &&
                (open ? (
                  <>
                    <CreditScoreIcon /> <span>Admin</span>
                  </>
                ) : (
                  <CreditScoreIcon />
                ))}
            </div>
            {isTablet && (
              <div
                className={open ? "openCloseBtn open" : "openCloseBtn close"}
                onClick={() => setOpen(!open)}
              >
                {open ? (
                  <ArrowBackIosNewOutlinedIcon />
                ) : (
                  <ArrowForwardIosOutlinedIcon />
                )}
              </div>
            )}
          </div>
        </div>
        <div className="tabContent">
          {/* Render the nested components here */}
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default ManageUsercompany;
