import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(
  function Transition(props, ref, title, message) {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

const AttentionDialog = ({
  open,
  handleOK,
  handleCancel,
  title,
  message,
  okBtnText,
  cancelBtnText,
}) => {
  return (
    <>
      <Dialog
        className="attentionDialog"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCancel}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle textAlign={"center"}>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOK}>{okBtnText}</Button>
          <Button onClick={handleCancel}>{cancelBtnText}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AttentionDialog;
