import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { CustomPacmanLoader } from "../../commonComponents/PacmanLoader";
import "./candidateDialog.scss";
import AccordionDetails from "./AccordionDetails";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const CandidateDialog = ({
  dialogOpen,
  handleCloseDialog,
  selectedCandidate,
}) => {
  const { id } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [cvPath, setCvPath] = useState(null);

  useEffect(() => {
    if (selectedCandidate && selectedCandidate.cvSkills) {
      const fetchCv = async () => {
        try {
          const response = await axios.get(
            `${selectedCandidate.cvSkills.filePath}`,
            {
              // headers: {
              //   Authorization: "Bearer " + (await getAccessTokenSilently()),
              // },
              responseType: "blob", // Important to handle files
            }
          );
          if (response.status === 200) {
            const fileUrl = URL.createObjectURL(response.data);
            setCvPath(fileUrl);
          }
        } catch (error) {
          console.error(error);
        }
      };

      fetchCv();
    }
  }, [selectedCandidate, id, getAccessTokenSilently]);

  const handleOpenCv = () => {
    if (cvPath) {
      window.open(cvPath, "_blank"); // Open the PDF in a new tab
    } else {
      console.error("CV path is not available.");
    }
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleCloseDialog}
      className="candidateDialog"
    >
      {!selectedCandidate && <CustomPacmanLoader />}
      {!selectedCandidate && <CustomPacmanLoader />}
      <DialogTitle textAlign={"center"}>
        {selectedCandidate.fullName} Details
      </DialogTitle>
      <DialogContent>
        {selectedCandidate ? (
          <div>
            <p>
              <strong>Name:</strong> {selectedCandidate.fullName}
            </p>
            <p>
              <strong>Email:</strong> {selectedCandidate.userEmail}
            </p>
            <p>
              <strong>Status:</strong> {selectedCandidate.candidateStatus}
            </p>
            <p>
              <strong>Decision:</strong> {selectedCandidate.decision}
            </p>
            <p>
              <strong>Score:</strong> {selectedCandidate.score}
            </p>
            <p>
              <strong>CV:</strong>
              <Button
                onClick={handleOpenCv}
                variant="outlined"
                color="primary"
                style={{
                  marginLeft: "10px",
                  height: "25px",
                  textTransform: "none",
                }}
                disabled={!cvPath}
              >
                View CV
              </Button>
            </p>

            <div className="accordionsSection">
              {selectedCandidate.tellAboutSelfSection && (
                <AccordionDetails
                  title="Candidate Self-Introduction"
                  data={selectedCandidate.tellAboutSelfSection}
                  video={selectedCandidate.tellAboutSelfSection.videoLink}
                />
              )}
              {selectedCandidate.knowledgeItemsSection && (
                <AccordionDetails
                  title="Knowledge Questions"
                  data={selectedCandidate.knowledgeItemsSection}
                />
              )}
              {selectedCandidate.bonusKnowledgeSection && (
                <AccordionDetails
                  title="Bonus Knowledge Questions"
                  data={selectedCandidate.bonusKnowledgeSection}
                />
              )}

              {selectedCandidate.specificQuestionsSection && (
                <AccordionDetails
                  title="Specific Questions"
                  data={selectedCandidate.specificQuestionsSection}
                />
              )}
              {selectedCandidate.bonusSpecificQuestionsSection && (
                <AccordionDetails
                  title="Bonus Specific Questions"
                  data={selectedCandidate.bonusSpecificQuestionsSection}
                />
              )}

              {selectedCandidate.codingSection && (
                <AccordionDetails
                  title="Coding Assignment"
                  data={selectedCandidate.codingSection}
                />
              )}

              {selectedCandidate.cvSection && (
                <AccordionDetails
                  title="CV Knowledge Questions"
                  data={selectedCandidate.cvSection}
                />
              )}
            </div>
          </div>
        ) : (
          <p>No details available.</p>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CandidateDialog;
