import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { DECISION_ON_CANDIDATE } from "../../../Utils/constants";
import { getKeyByValue } from "../../../Utils/Utils";
import { CustomPacmanLoader } from "../../commonComponents/PacmanLoader";
import { toast } from "react-toastify";

const CandidateTable = ({ candidates, columnsData, loading }) => {
  const [candidatesList, setCandidatesList] = useState([...candidates]);
  const { getAccessTokenSilently } = useAuth0();
  const { id } = useParams();

  useEffect(() => {
    if (JSON.stringify(candidatesList) !== JSON.stringify(candidates)) {
      setCandidatesList([...candidates]);
    }
  }, [candidates]);

  const onDecisionChange = (candidateId, newDecision) => {
    setCandidatesList((prevCandidates) =>
      prevCandidates.map((candidate) =>
        candidate.id === candidateId
          ? { ...candidate, decision: newDecision }
          : candidate
      )
    );

    const updateDecision = async () => {
      const decision = getKeyByValue(DECISION_ON_CANDIDATE, newDecision);
      try {
        await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/api/candidate/${id}/${candidateId}/updateCandidate`,
          { decision: decision },
          {
            headers: {
              Authorization: "Bearer " + (await getAccessTokenSilently()),
            },
          }
        );
      } catch (error) {
        console.error(error);
      }
    };

    updateDecision();
  };

  const columns = useMemo(() => {
    return columnsData.map((col) => {
      if (col.accessorKey === "decision") {
        return {
          ...col,
          Cell: ({ row }) => {
            const decision = row.original.decision || "";
            const handleDecisionChange = (e) => {
              const newDecision = e.target.value;
              onDecisionChange(row.original.id, newDecision);
            };

            return (
              <select
                disabled={row.original.isTemporary}
                value={decision}
                onChange={handleDecisionChange}
                style={{
                  borderRadius: "5px",
                  border: "1px solid gray",
                  maxHeight: "27px",
                  height: "27px",
                  maxWidth: "110px",
                  width: "110px",
                  borderBlockColor: "#278aac",
                }}
              >
                <option value={DECISION_ON_CANDIDATE.NotDecided}>
                  {DECISION_ON_CANDIDATE.NotDecided}
                </option>
                <option value={DECISION_ON_CANDIDATE.Hired}>
                  {DECISION_ON_CANDIDATE.Hired}
                </option>
                <option value={DECISION_ON_CANDIDATE.Rejected}>
                  {DECISION_ON_CANDIDATE.Rejected}
                </option>
              </select>
            );
          },
        };
      }

      return col;
    });
  }, [columnsData]);

  const handleCellEditing = async (rowIndex, value) => {
    const editedCandidateId = candidatesList[rowIndex].id;
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/candidate/${id}/${editedCandidateId}/updateExtractedCandidate`,
        {
          fullName: value.fullName,
          email: value.userEmail,
        },
        {
          headers: {
            Authorization: "Bearer " + (await getAccessTokenSilently()),
          },
        }
      );

      if (response.status === 200) {
        toast.success("Candidate updated successfully");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error while updating candidate");
    }
  };

  const isAnyTemporary = candidatesList.some(
    (candidate) => candidate.isTemporary
  );

  const table = useMaterialReactTable({
    columns: columns,
    data: candidatesList,
    enableColumnFilters: true,
    enablePagination: true,
    enableSorting: true,
    enableColumnOrdering: false,
    enableStickyHeader: true,
    enableRowSelection: false,
    enableColumnPinning: true,
    enableColumnActions: false,
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "Edit", //change header text
        size: isAnyTemporary ? 20 : 0, //make actions column wider
      },
    },
    enableEditing: (row) => {
      if (!isAnyTemporary) {
        return false;
      } else if (row.original.isTemporary) {
        return true;
      }
      return false;
    },
    editDisplayMode: "modal",
    onEditingRowSave: ({ row, values }) => {
      handleCellEditing(row.index, values);
      table.setEditingRow(null);
    },
    muiTableContainerProps: { sx: { maxHeight: "900px" } },
    initialState: {
      density: "compact",
    },
    muiTableBodyProps: {
      sx: {
        "& .MuiTableRow-root": {
          cursor: "pointer",
        },
      },
    },
  });

  return (
    <>
      {loading && <CustomPacmanLoader />}
      {!loading && <MaterialReactTable table={table} />}
    </>
  );
};

export default CandidateTable;
