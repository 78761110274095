// src/SignUp.js
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/userSlice.js";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
} from "@mui/material";
import wantedImg from "../../assets/imgs/wanted.jpg";
import "./signUp.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const { loginWithRedirect } = useAuth0();
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();
  console.log(user, isAuthenticated, isLoading);
  const dispatch = useDispatch();
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(setUser(user));
    }

    const getToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken);
        localStorage.setItem("auth0Token", accessToken);
        sessionStorage.setItem("auth0Token", accessToken);
      } catch (error) {
        console.error("Error fetching access token:", error);
      }
    };

    if (isAuthenticated) {
      getToken();
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/Auth/login`,
          {
            email: user.email,
            fullname: user.name,
            token: token,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200) {
          console.log("User logged in successfully");
          navigate("/create-company");
        }
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    if (token) {
      getUser();
    }
  }, [token, user, navigate]);

  const handleSignUp = () => {
    loginWithRedirect({
      screen_hint: "signup",
    });
  };

  return (
    <div className="signUp">
      <Card className="signUpCard">
        <CardMedia
          component="img"
          alt="green iguana"
          height="100"
          image={wantedImg}
        />
        <CardContent className="signUpContent">
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            textAlign={"center"}
          >
            AI Interviewer
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Welcome to AI Interviewer! Please sign up to continue. More text
            here..
          </Typography>
        </CardContent>
        <CardActions className="signUpBtns">
          <Button onClick={handleSignUp}>Login</Button>
          {/* <Button size="small">Learn More</Button> */}
        </CardActions>
      </Card>{" "}
    </div>
  );
};

export default SignUp;
