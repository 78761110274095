import React from "react";
import "./App.css";
import { Provider } from "react-redux";
import ComponentsWrapper from "./components/componentsWrapper";
import { Auth0Provider } from "@auth0/auth0-react";
import store from "./redux/store";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

function App() {
  return (
    <Provider store={store}>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        cacheLocation="localstorage"
        authorizationParams={{
          audience:
            process.env.REACT_APP_AUTH0_AUDIENCE ??
            "https://dev-xmq7cs4cn07gy2v8.us.auth0.com/api/v2/",
          redirect_uri: window.location.origin, // This specifies where users are redirected after login
        }}
      >
        <ComponentsWrapper />
      </Auth0Provider>
    </Provider>
  );
}

export default App;
