import React from "react";
import PositionForm from "./AddPositionForm.jsx";

const AddPosition = () => {
  return (
    <div>
      <PositionForm />
    </div>
  );
};

export default AddPosition;

